import { NgModule } from '@angular/core';
import { SocialShareDirective } from './ngx-social-share.directive';

@NgModule({
  imports: [
  ],
  declarations: [
    SocialShareDirective
  ],
  exports: [
    SocialShareDirective
  ]
})
export class NgxSocialShareModule { }
